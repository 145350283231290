/*
 * @Descripttion:
 * @Author: Yi Yunwan
 * @Date: 2020-09-07 11:58:13
 * @LastEditors: yaozuo
 * @LastEditTime: 2021-11-19 10:07:21
 */
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule
} from 'vuex-module-decorators'
import store from '@/store'
import {
  callAppFunc_getSomeParams,
  callAppFunc_Jump2Act
} from '@/common/utils/bridge'
import { getDeviceId, getQueryVariable } from '@/common/utils'
import Cookie from 'js-cookie'
import { APP_VERSION } from '@/config'
import { getChargeList } from '@/pages/user/api'
@Module({ dynamic: true, store, name: 'Common' })
class Common extends VuexModule {
  /**用户token */
  token = Cookie.get('kigo_token') || localStorage.getItem('kigo_token') || ''
  //token =
  //  'eyJ0eXAiOiJqd3QiLCJhbGciOiJSUzI1NiJ9.eyJ1aWQiOjE1LCJ1c2VyX2xvZ2luIjoianN3bWRseiIsImV4cCI6MjAxMzU3MzY3MX0.b5BvCPcF5Qw7IwMCs0CNxFpSJgn2XL5RYYB85ndy1ILRCgCNByKdKJaTIxCk_ZUBOSS-Z2iD9dnTzVLAhq_K5oetaAQ_SPlvhhCedRS3Mk9r7ECnUj6M_O1bT4Z5eDz0fBK0Eo7zf3tWl25SCxdb28Q1PhldJCsluFO7msa6k1o'

  oldToken = Cookie.get('taktak_old_token') || ''
  /**用户id */
  userId: string | number =
    getQueryVariable('uid') ||
    Cookie.get('kigo_userid') ||
    localStorage.getItem('kigo_userid') ||
    ''
  // userId: string | number =
  //   getQueryVariable('uid') || Cookie.get('kigo_userid') || '100000489'
  userName: string =
    getQueryVariable('username') ||
    Cookie.get('kigo_username') ||
    localStorage.getItem('kigo_username') ||
    '小乐'
  /**设备id */
  deviceId = getDeviceId()
  /**设备型号 */
  deviceModel = ''
  /**app版本 */
  appVersion = APP_VERSION
  userType = ''
  isLogin = false
  /**渠道 */
  channel = getQueryVariable('channel') || ''
  //金币
  coin = 0
  //金豆
  k_bean = 0
  //银豆
  k_coin = 0
  //安卓或者ios渠道
  platform = ''
  //是否展示下载确认弹窗
  isShowDownloadConfirm = false
  //来源是否是kigo电玩
  requestFrom =
    getQueryVariable('kigo_request_from') ||
    Cookie.get('kigo_request_from') ||
    localStorage.getItem('kigo_request_from') ||
    ''
  @Mutation
  setUserId(userId: string) {
    if (userId) {
      this.userId = userId
    }
  }
  @Mutation
  setUserName(userName: string) {
    console.log('setUserName', userName)
    if (userName) {
      this.userName = userName
    }
  }
  @Mutation
  setDeviceId(deviceId: string) {
    if (deviceId) this.deviceId = deviceId
  }
  @Mutation
  setDeviceModel(deviceModel: string) {
    if (deviceModel) this.deviceModel = deviceModel
  }
  @Mutation
  setAppVersion(appVersion: string) {
    if (appVersion) this.appVersion = appVersion
  }

  @Mutation
  setToken(token: string) {
    console.log('setToken', token)
    this.token = token
  }

  @Mutation
  setChannel(channel: string) {
    this.channel = channel
  }

  @Mutation
  setUserType(userType: string) {
    this.userType = userType || ''
  }

  @Mutation
  setLogin(isLogin: boolean) {
    this.isLogin = isLogin
  }
  @Mutation
  setPlatform(val: string) {
    this.platform = val
  }

  @Mutation
  setShowDownloadConfirm(val: boolean) {
    this.isShowDownloadConfirm = val
  }
  @Action
  async getToken() {
    try {
      const res = await callAppFunc_getSomeParams()
      console.log('callAppFunc_getSomeParams:', res)

      if (res.isLogin) {
        this.setLogin(true)
      }
      console.log('callAppFunc_getSomeParams tocken', res.tocken)
      this.setToken(res.tocken)
      this.setUserName(res.userName)
      this.setUserId(res.userId)
      this.setDeviceId(res.deviceId)
      //this.setUserType(res.userType)
      this.setDeviceModel(res['device-model'])
      this.setAppVersion(res['app-version'])
      this.setChannel(res['appChannel'])
      this.setPlatform(res.platform)
      return true
    } catch (error) {
      console.error(error)
    }
  }

  @Mutation
  setOldToken(token: string) {
    this.oldToken = token
  }

  keepAlive: string[] = ['HelpFeedback']

  @Mutation
  addKeepAlive(name: string) {
    if (!this.keepAlive.includes(name)) {
      this.keepAlive.push(name)
    }
  }
  @Action
  login() {
    callAppFunc_Jump2Act()
  }
  @Mutation
  setCoin(val: number) {
    this.coin = val
  }
  @Mutation
  setKCoin(val: number) {
    this.k_coin = val
  }
  @Mutation
  setKBean(val: number) {
    this.k_bean = val
  }
  @Action
  async getCoin() {
    const { data } = await getChargeList()

    this.setCoin(data.coin)
  }
}

export const CommonModule = getModule(Common)

export async function beforeEnter() {
  console.log('beforeEnter', CommonModule.token)
  if (CommonModule.token) {
    await CommonModule.getToken()
    return
  } else {
    console.time('getToken')
    await CommonModule.getToken()
    console.timeEnd('getToken')
    return
  }
}
