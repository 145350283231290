/*
 * @Descripttion:
 * @Author: Yi Yunwan
 * @Date: 2020-09-17 15:14:00
 * @LastEditors: Yi Yunwan
 * @LastEditTime: 2020-09-17 15:15:21
 */

/**
 * 获取网络类型
 */
export function getNetworkType() {
  const connection = navigator.connection || navigator.webkitConnection

  let networkType = 'unknown'
  if (connection) {
    networkType = connection.type
    if (networkType === 'cellular' && connection.effectiveType) {
      networkType = connection.effectiveType.replace('slow-', '')
    } else if (!['none', 'wifi'].includes(networkType)) {
      networkType = 'unknown'
    }
  } else if (navigator.onLine === false) {
    networkType = 'none'
  }
  return networkType
}
