






















import { Component, Mixins, Prop } from 'vue-property-decorator'
import { OutsidePageMixin } from '@/mixins/outsidePage'

@Component({
  name: 'ToDownLoad',
})
export default class ToDownLoad extends Mixins(OutsidePageMixin) {
  @Prop({
    type: String,
    default: '立即下载',
  })
  btnTxt!: string
  @Prop({
    type: String,
    default: '去kigo，发现更多精彩内容',
  })
  titleTxt!: string

  get toLookEvent() {
    return {
      pageId: '',
      eventId: 'toLookBtn',
      eventType: 'isDownloadApp',
      shareUserId: '',
    }
  }

  // handleOpenApp() {
  //   console.log('')
  // }
}
