/*
 * @Descripttion:
 * @Author: Yi Yunwan
 * @Date: 2020-09-07 11:58:13
 * @LastEditors: Yi Yunwan
 * @LastEditTime: 2021-06-07 14:40:13
 */
import { VuexModule, Module, Mutation, getModule } from 'vuex-module-decorators'
import store from '@/store'

@Module({ dynamic: true, store, name: 'Loyout' })
class Loyout extends VuexModule {
  title = 'KIGO'
  liveEndToastStatus = {
    liveuid: '',
    status: false
  }

  @Mutation
  setLiveEndToastStatus(liveuid: string, status: boolean) {
    this.liveEndToastStatus = {
      liveuid,
      status
    }
  }

  @Mutation
  setTitle(title: any) {
    if (title === -1) {
      this.title = '  '
    } else {
      this.title = title || '加载中'
    }

    document.title = this.title
  }
}

export const LoyoutModule = getModule(Loyout)
