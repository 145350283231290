/*
 * @Descripttion:
 * @Author: Yi Yunwan
 * @Date: 2021-06-02 10:45:18
 * @LastEditors: Yi Yunwan
 * @LastEditTime: 2021-06-07 17:04:13
 */
import QueryString from 'qs'
import { guid } from '.'

interface GameJsBridgeCallBackResponse {
  /**
   * js发送给原生的funcId
   */
  funcId: string
  data: string
  /**
   * type:
   */
  type: 'callHandler' | 'callBack'
  /**
   * 调用的js方法
   */
  name: string
}

type ResponseCallback = (data: any) => void

export class GameJsBridge implements WebViewJavascriptBridge {
  init() {
    window.evalJsBridgeCallBack = (data: string) => {
      console.log(data)

      const response: GameJsBridgeCallBackResponse = JSON.parse(data)
      if (response.type === 'callHandler') {
        console.log(response)

        /**
         * 执行注册的回调函数
         */
        const func = this.jsBridgeCallBack[response.funcId]
        func && func(response.data)
        delete this.jsBridgeCallBack[response.funcId]
      } else if (response.type === 'callBack') {
        const callBack = (data: string) => {
          this.sendScheme({
            funcId: response.funcId,
            name: response.name,
            data,
            type: 'callBack'
          })
          // 执行js桥
        }
        const func = this.webviewJsBridgeCallBack[response.name]
        console.log(this.webviewJsBridgeCallBack, response, func)
        func && func(response.data, callBack)
      }
    }
  }
  sendScheme(data: any) {
    const iframe = document.createElement('iframe')
    iframe.style.width = '0'
    iframe.style.height = '0'

    iframe.src = `testkey://callBack?${QueryString.stringify(data)}`

    document.body.appendChild(iframe)
    document.body.removeChild(iframe)
  }

  callHandler(
    /**
     * App那边注册的方法
     */
    name: string,
    /**
     * 上传给App的数据，无为空
     */
    data: string,
    /**
     * 回调函数
     */
    responseCallback: ResponseCallback
  ) {
    const funcId = guid()
    this.jsBridgeCallBack[funcId] = responseCallback
    /**
     * scheme协议上传回调funcID,调用的App注册的方法ID,以及App需要的数据
     */
    this.sendScheme({
      funcId,
      name,
      data,
      type: 'callHandler'
    })
  }

  jsBridgeCallBack: Record<string, (data: string) => void> = {}

  registerHandler(
    /**
     * 注册的与js通信事件名字
     */
    name: string,
    /**
     * 原生调用的回调函数
     */
    responseCallback: (data: any, callBack: (data: string) => void) => void
  ) {
    console.log(name, responseCallback)
    console.log(this.webviewJsBridgeCallBack)
    this.webviewJsBridgeCallBack[name] = responseCallback
    setTimeout(() => {
      console.log(this.webviewJsBridgeCallBack)
    })
  }

  webviewJsBridgeCallBack: Record<
    string,
    (data: string, callBack: (data: string) => void) => void
  > = {}

  send() {
    console.log('send')
  }
}
window.isCheckCheck = location.href.toLocaleLowerCase().includes('chekchek')
export const gameJsBridge = new GameJsBridge()
