/*
 * @Descripttion: 所有vue页面都需导入使用的文件，主要提供全局守卫
 * @Author: Feng Ziming
 * @Date: 2021-02-02 15:02:39
 * @LastEditors: Yi Yunwan
 * @LastEditTime: 2021-08-19 12:21:27
 */

import { Route, NavigationGuardNext } from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { CommonModule, beforeEnter } from '@/store/modules/common'
import { getPlatform } from '@/common//utils'
import {
  callAppFunc_setTitleFontColor,
  callAppFunc_setNavColor
} from '@/common//utils/bridge'
import { LoyoutModule } from '@/store/modules/layout'
import { getAppLanguage } from '@/common/utils'
import VueI18n from 'vue-i18n'
NProgress.configure({ showSpinner: false })
const lang = getAppLanguage()
// const i18n = new VueI18n({
//   locale: lang ? lang : 'zh_CN',
//   //locale: 'uyghur',
//   messages: {
//     zh_CN: require('../../lang/zh.json'),
//     uyghur: require('../../lang/uyghur.json')
//   }
// })
/**
 * 路由加载前的处理
 */
export async function globalBeforeRouter(
  to: Route,
  from: Route,
  next: NavigationGuardNext<Vue>
) {
  console.log('globalBeforeRouter', to)
  console.log('process.env.NODE_ENV', process.env.NODE_ENV)
  if (!to.meta) {
    to.meta = {}
  }
  // 模拟加载条
  !to.meta.hideNProgress && NProgress.start()

  to.meta.pageStartTime = new Date().getTime()

  // 路由缓存处理
  if (to.meta.cache && to.name) {
    CommonModule.addKeepAlive(to.name)
  }

  if (to.meta.showNavBack) {
    if (from.fullPath === '/') {
      to.meta.showNavBack = false
    }
  }

  if (process.env.NODE_ENV == 'development') {
    next()
    return
  }

  // 如果页面需要token
  if (to.meta.auth) {
    await beforeEnter()
    // 如果页面需要登录才能访问
    if (to.meta.login) {
      if (!CommonModule.token) {
        CommonModule.login()
      }
    }
  }

  next()
}

/**
 * 运行平台
 */
const platform = getPlatform()

// 全局路由加载完成需要做的操作
export async function globalAfterRouter(this: any, to: Route) {
  if (!to.meta) {
    to.meta = {}
  }
  // 获取页面加载时间
  to.meta.pageLoadTime = new Date().getTime() - to.meta.pageStartTime

  // 关闭自定义加载条
  NProgress.done()

  /**
   * 如果在 kixmix 或 taktak App内打开根据配置修改导航栏
   */
  if (platform && ['taktak', 'kixmix'].includes(platform)) {
    const navColor: string = to.meta.navColor || '#ffffff'
    const navFontColor: string = to.meta.titleColor || '#000000'
    to.meta.navColor && callAppFunc_setNavColor({ navColor })
    to.meta.titleColor && callAppFunc_setTitleFontColor({ navFontColor })
  }

  LoyoutModule.setTitle(to.meta.title)
}
