/*
 * @Descripttion:
 * @Author: Yi Yunwan
 * @Date: 2020-09-22 19:21:25
 * @LastEditors: Yi Yunwan
 * @LastEditTime: 2021-03-04 15:43:47
 */
import { point } from '@/common/utils/point'
import { VueConstructor } from 'vue'
import { Component, Vue } from 'vue-property-decorator'
import { isIOS } from '../utils'

@Component
export class PageReport extends Vue {
  _postPageData(sync = false) {
    const pageEvent: PageEventMap = {
      ...this.pageEvent,
      outTime: new Date().getTime()
    }
    point.pageReport(pageEvent, sync)
  }

  pageEvent!: PageEventMap

  mounted() {
    if (this.$options.name == this.$route?.name && this.$route.meta?.report) {
      this.pageEvent = {
        pageId: this.$route.meta?.pageId,
        pageName: this.$route.meta?.pageName,
        pageType: this.$route.meta?.pageType,
        inTime: this.$route.meta?.pageStartTime,
        getDataTime: this.$route.meta?.pageLoadTime
      }
      console.log('test')
      if (isIOS) {
        const fun = () => {
          this._postPageData(true)
        }

        if ('onpagehide' in window) {
          window.addEventListener('pagehide', fun, false)
        } else {
          window.addEventListener('unload', fun, false)
        }

        document.addEventListener('pagehide', fun, false)
        this.$once('hook:beforeDestroy', () => {
          document.removeEventListener('pagehide', fun)
        })
      }

      window.onbeforeunload = () => {
        this._postPageData(true)
      }
    }
  }
  deactivated() {
    if (this.pageEvent) {
      this._postPageData()
    }
  }

  beforeDestroy() {
    if (this.pageEvent) {
      this._postPageData()
    }
  }
}

export function pageReport(vue: VueConstructor) {
  vue.mixin(PageReport)
}
