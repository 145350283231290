/*
 * @Descripttion:
 * @Author: Yi Yunwan
 * @Date: 2021-03-15 17:38:00
 * @LastEditors: Yi Yunwan
 * @LastEditTime: 2021-04-01 10:57:33
 */
/**
 * 手机号过滤
 * @param value
 * @returns {string|*}
 */
export function phone(value: string): string | any {
  if (!value) return ''
  const preg = /1[3-9]+\d{9}/
  const reg = /^(\d{3})\d{4}(\d{4})$/
  if (preg.test(value)) {
    //手机号
    value = value.replace(reg, '$1****$2')
  }
  return value
}

/**
 * 时间格式化
 * @param value
 * @returns {*}
 */
export function iosTime(value: string): any {
  if (!value) return value
  value = value.replace(/-/g, '/') //兼容iOS
  return value
}

/**
 * 格式化人气值
 * @param value
 * @returns {string|number|*}
 */
export function filtersRenqi(value: number): string | number | any {
  if (!value) return 0
  if (value < 1000) {
    return value
  } else if (value >= 1000 && value < 10000) {
    return Math.floor(value / 100) / 10 + 'k'
  } else if (value >= 10000) {
    return Math.floor(value / 1000) / 10 + 'w'
  }
}

/**
 * 格式化关注，礼物，粉丝数量
 * @param value
 * @returns {string|number|*}
 */
export function filtersCountWan(value: number): string | number {
  if (!value) return 0
  if (value >= 10000) {
    return (Math.floor(value / 1000) / 10).toFixed(1) + 'w'
  }
  return value
}
