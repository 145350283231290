/* eslint-disable no-useless-escape */
import safeAreaInsets from 'safe-area-insets'

const ua = navigator.userAgent
/**
 * 是否安卓设备
 */
export const isAndroid = /android/i.test(ua)
/**
 * 是否iOS设备
 */
export const isIOS = /iphone|ipad|ipod/i.test(ua)
/**
 * 获取系统信息-同步
 */
export function getSystemInfoSync() {
  const screen = window.screen
  const pixelRatio = window.devicePixelRatio
  // 横屏时 iOS 获取的屏幕宽高颠倒，进行纠正
  const landscape = Math.abs(Number(window.orientation)) === 90
  const screenWidth = Math[landscape ? 'max' : 'min'](
    screen.width,
    screen.height
  )
  const screenHeight = Math[landscape ? 'min' : 'max'](
    screen.height,
    screen.width
  )
  const windowWidth = Math.min(
    window.innerWidth,
    document.documentElement.clientWidth,
    screenWidth
  )
  const windowHeight = window.innerHeight
  const language = navigator.language
  const statusBarHeight = safeAreaInsets.top
  let osname
  let osversion
  let model

  if (isIOS) {
    osname = 'iOS'
    const osversionFind = ua.match(/OS\s([\w_]+)\slike/)
    if (osversionFind) {
      osversion = osversionFind[1].replace(/_/g, '.')
    }
    const modelFind = ua.match(/\(([a-zA-Z]+);/)
    if (modelFind) {
      model = modelFind[1]
    }
  } else if (isAndroid) {
    osname = 'Android'
    const osversionFind = ua.match(/Android[\s/]([\w\.]+)[;\s]/)
    if (osversionFind) {
      osversion = osversionFind[1]
    }
    const infoFind = ua.match(/\((.+?)\)/)
    const infos = infoFind ? infoFind[1].split(';') : ua.split(' ')
    const otherInfo = [
      /\bAndroid\b/i,
      /\bLinux\b/i,
      /\bU\b/i,
      /^\s?[a-z][a-z]$/i,
      /^\s?[a-z][a-z]-[a-z][a-z]$/i,
      /\bwv\b/i,
      /\/[\d\.,]+$/,
      /^\s?[\d\.,]+$/,
      /\bBrowser\b/i,
      /\bMobile\b/i
    ]
    for (let i = 0; i < infos.length; i++) {
      const info = infos[i]
      if (info.indexOf('Build') > 0) {
        model = info.split('Build')[0].trim()
        break
      }
      let other
      for (let o = 0; o < otherInfo.length; o++) {
        if (otherInfo[o].test(info)) {
          other = true
          break
        }
      }
      if (!other) {
        model = info.trim()
        break
      }
    }
  } else {
    osname = 'Other'
    osversion = '0'
  }

  const system = `${osname} ${osversion}`
  const platform = osname.toLocaleLowerCase()
  const safeArea = {
    left: safeAreaInsets.left,
    right: windowWidth - safeAreaInsets.right,
    top: safeAreaInsets.top,
    bottom: windowHeight - safeAreaInsets.bottom,
    width: windowWidth - safeAreaInsets.left - safeAreaInsets.right,
    height: windowHeight - safeAreaInsets.top - safeAreaInsets.bottom
  }

  return {
    windowWidth,
    windowHeight,
    pixelRatio,
    screenWidth,
    screenHeight,
    language,
    statusBarHeight,
    system,
    platform,
    model,
    safeArea,
    safeAreaInsets: {
      top: safeAreaInsets.top,
      right: safeAreaInsets.right,
      bottom: safeAreaInsets.bottom,
      left: safeAreaInsets.left
    }
  }
}
/**
 * 获取系统信息-异步
 */
export function getSystemInfo() {
  return getSystemInfoSync()
}
