/*
 * @Descripttion:
 * @Author: Yi Yunwan
 * @Date: 2020-09-07 11:47:40
 * @LastEditors: yaozuo
 * @LastEditTime: 2021-09-03 10:56:05
 */
import {
  callAppFunc_getSomeParams,
  callAppFunc_jumpToLiveRoom,
  callAppFunc_jumpToPersonalCenter,
  callAppFunc_openPersonalDataPopUp
} from './bridge'
import { isAndroid, isIOS } from './device'
import { TakStorage } from './storage'
import CryptoJS from 'crypto-js'
import qs from 'qs'
import { PUBLIC_KEY } from '@/config'
import JSEncrypt from 'jsencrypt'
import { Toast } from 'vant'
export * from './device/getSystemInfo'
export * from './device/getNetworkType'
import { getLaestVserionInfo } from '@/pages/videoGame/api'
export async function getToken() {
  const res = await callAppFunc_getSomeParams()
  return res.newToken
}

export const _utf8_encode = function(string: string) {
  string = string.replace(/\r\n/g, '\n')
  let utftext = ''
  for (let n = 0; n < string.length; n++) {
    const c = string.charCodeAt(n)
    if (c < 128) {
      utftext += String.fromCharCode(c)
    } else if (c > 127 && c < 2048) {
      utftext += String.fromCharCode((c >> 6) | 192)
      utftext += String.fromCharCode((c & 63) | 128)
    } else {
      utftext += String.fromCharCode((c >> 12) | 224)
      utftext += String.fromCharCode(((c >> 6) & 63) | 128)
      utftext += String.fromCharCode((c & 63) | 128)
    }
  }
  return utftext
}

export const encode = function(input: string) {
  const _keyStr =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/='
  let output = ''
  let chr1, chr2, chr3, enc1, enc2, enc3, enc4
  let i = 0
  input = _utf8_encode(input)
  while (i < input.length) {
    chr1 = input.charCodeAt(i++)
    chr2 = input.charCodeAt(i++)
    chr3 = input.charCodeAt(i++)
    enc1 = chr1 >> 2
    enc2 = ((chr1 & 3) << 4) | (chr2 >> 4)
    enc3 = ((chr2 & 15) << 2) | (chr3 >> 6)
    enc4 = chr3 & 63
    if (isNaN(chr2)) {
      enc3 = enc4 = 64
    } else if (isNaN(chr3)) {
      enc4 = 64
    }
    output =
      output +
      _keyStr.charAt(enc1) +
      _keyStr.charAt(enc2) +
      _keyStr.charAt(enc3) +
      _keyStr.charAt(enc4)
  }
  return output
}

export function guid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}
export type Platform =
  | 'kigo'
  | 'kixmix'
  | 'weixin'
  | 'qq'
  | 'taktak'
  | 'chekchek'
  | undefined
export function getPlatform(): Platform {
  const ua = navigator.userAgent
  if (/kigo/i.test(ua)) {
    return 'kigo'
  } else if (/kixmix/i.test(ua)) {
    return 'kixmix'
  } else if (/microMessenger/i.test(ua)) {
    return 'weixin'
  } else if (/qq\//i.test(ua)) {
    return 'qq'
  } else if (/taktak/i.test(ua)) {
    return 'taktak'
  } else if (/chekchek/i.test(ua)) {
    return 'chekchek'
  } else {
    // return 'kixmix'
  }
}

export function getDeviceId() {
  let deviceId = localStorage.getItem('deviceId')
  if (!deviceId) {
    deviceId = guid()
    localStorage.setItem('deviceId', deviceId)
  }
  return deviceId
}

export function useDebug() {
  if (
    window.location.href.includes('debug=true') ||
    window.navigator.userAgent.toLocaleLowerCase().includes('debug') ||
    window.TAKTAK_DEBUG
  ) {
    window.TAKTAK_DEBUG = true
  }
  //window.TAKTAK_DEBUG = true
  // if (window.location.origin.includes('test')) {
  //   window.TAKTAK_DEBUG = true
  // }
  if (window.TAKTAK_DEBUG) {
    import(/* webpackChunkName: "vconsole" */ 'vconsole').then(value => {
      const vconsole = value.default
      new vconsole()
    })
  }

  return window.TAKTAK_DEBUG || false
}

export const takStorage = new TakStorage()

export function openScheme(schemeUrl: string, timeout = 2500) {
  const startTime = new Date().getTime()
  window.location.href = schemeUrl
  return new Promise<boolean>((resolve, reject) => {
    const timer = setTimeout(() => {
      const endTime = new Date().getTime()
      if (endTime - startTime > timeout + 50) {
        resolve(true)
      }
      if (document.hidden) {
        resolve(true)
      } else {
        reject(false)
      }
    }, timeout)
    function visibilitychange() {
      clearTimeout(timer)
      resolve(true)
    }
    document.addEventListener('visibilitychange', visibilitychange)
  })
}

/**
 * 判断是否是oppo手机
 */
export const oppoReg = /OPPO|P[A-Z]{2}M[00-90]|X9007|N5117|R831S|CPH[0000-9999]|R[0000-9999]|A51|R7PLUS|A31|A31T|A31c|A31u/i

export function isOppoBrowser() {
  const ua = navigator.userAgent
  return oppoReg.test(ua) && /HeyTapBrowser/i.test(ua)
}

export function openAppMarket(packageName: string) {
  const appMarketList = [
    {
      reg: /MI|Mi|Redmi/i,
      scheme: `mimarket://details?id=${packageName}`
    },
    {
      reg: /SAMSUNG|SM-/i,
      scheme: `samsungapps://ProductDetail/${packageName}`
    },
    {
      reg: /HUAWEI|HONOR/i,
      scheme: `appmarket://details?id=${packageName}`
    },
    {
      reg: /vivo|v1928a|v1928t/i,
      scheme: `vivomarket://details?id=${packageName}`
    },
    {
      reg: oppoReg,
      scheme: `oppomarket://details?packagename=${packageName}`
    }
  ]

  const ua = navigator.userAgent
  for (let i = 0; i < appMarketList.length; i++) {
    const element = appMarketList[i]
    if (element.reg.test(ua)) {
      if (element.scheme.includes('oppomarket')) {
        openScheme(element.scheme).catch(() => {
          window.location.href = process.env.VUE_APP_JUMP_APP
          throw new Error('ToOppoAppMarket Maybe Fail')
        })
        return true
      }
      openScheme(element.scheme).catch(() => {
        throw new Error('ToAppMarket Maybe Fail')
      })
      return true
    }
  }
  return false
}
export function toAppMarket() {
  if (isAndroid) {
    const res = openAppMarket('com.kigo.app')
    if (!res) {
      window.location.href = process.env.VUE_APP_JUMP_APP
    }
  } else if (isIOS) {
    openScheme('https://itunes.apple.com/app/id1625991865').catch(() => {
      throw new Error('ToAppMarket Maybe Fail')
    })
  }
}

export async function toAppMarketEnjoy(downLoadUrl: string) {
if (isIOS) {
    openScheme('https://itunes.apple.com/app/id6449921184').catch(() => {
      throw new Error('ToAppMarket Maybe Fail')
    })
  }else {
    // const { data } = await getLaestVserionInfo()
    // window.location.href = data.apkUrl
    window.location.href = 'https://qiniu.djll.fun/youYiTang_140_bing.apk'
  }
}
export function openSchemeNew(schemeUrl: string, timeout = 2500) {
  if (isAndroid) {
    window.location.href = schemeUrl
    window.setTimeout(() => {
      const res = openAppMarket('com.kigo.app')
      if (!res) {
        window.location.href = process.env.VUE_APP_JUMP_APP
      }
    }, 2000)
  } else if (isIOS) {
    const ifr = document.createElement('iframe')
    ifr.src = schemeUrl
    ifr.style.display = 'none'
    document.body.appendChild(ifr)
    // window.setTimeout(() => {
    //   document.body.removeChild(ifr)
    //   window.location.href = 'https://itunes.apple.com/app/id1625991865'
    // }, 2000)
  }
}

/**
 * - horizontal 横屏
 * - half 半屏
 * - vertical 竖屏
 */
export function getScreen() {
  const ua = navigator.userAgent.toLocaleLowerCase()
  if (ua.includes('horizontal')) {
    return 'horizontal'
  } else if (ua.includes('half')) {
    return 'half'
  } else {
    return 'vertical'
  }
}

/**
 * - uyghur 维语
 * - en 英文
 * - zh_CN 中文
 */
export function getAppLanguage() {
  const ua = navigator.userAgent.toLocaleLowerCase()
  if (ua.includes('language/uyghur')) {
    return 'uyghur'
  } else if (ua.includes('language/en')) {
    return 'en'
  } else {
    return 'zh_CN'
  }
  //return 'uyghur'
}

export function compare(curV: string, reqV: string) {
  if (curV && reqV) {
    //将两个版本号拆成数字
    const arr1 = curV.split('.'),
      arr2 = reqV.split('.')
    const minLength = Math.min(arr1.length, arr2.length)
    let position = 0,
      diff = 0
    //依次比较版本号每一位大小，当对比得出结果后跳出循环（后文有简单介绍）
    while (
      position < minLength &&
      (diff = parseInt(arr1[position]) - parseInt(arr2[position])) == 0
    ) {
      position++
    }
    diff = diff != 0 ? diff : arr1.length - arr2.length
    //若curV大于reqV，则返回true
    return diff > 0
  } else {
    //输入为空
    console.log('版本号不能为空')
    return false
  }
}
/**
 * 保留n位小数并格式化输出（不足的部分补0）
 * @param value
 * @param n
 * @returns
 */
export function fomatFloat(value: number, n: number) {
  const f = Math.floor(value * Math.pow(10, n)) / Math.pow(10, n)
  let s = f.toString()
  const rs = s.indexOf('.')
  if (rs < 0) {
    s += '.'
  }
  for (let i = s.length - s.indexOf('.'); i <= n; i++) {
    s += '0'
  }
  return s
}
/**
 *
 * @param uid
 * @param isLive
 * @param fromType
 * @returns
 */
export function toLiveRoom(
  uid: string | number | 0,
  isLive: boolean | 0 | 1 = 0,
  fromType = 0
) {
  if (!uid) {
    return
  }
  if (isLive) {
    return callAppFunc_jumpToLiveRoom({ roomId: uid, fromType: fromType })
  } else {
    if (getScreen() !== 'vertical') {
      return callAppFunc_openPersonalDataPopUp(uid)
    } else {
      return callAppFunc_jumpToPersonalCenter(uid)
    }
  }
}

/**
 * base64转Blob
 * @param dataurl
 * @returns
 */
export function base64ToBlob(base64Data: string) {
  const bstr = atob(base64Data)
  let l = bstr.length
  const u8Arr = new Uint8Array(l)
  while (l--) {
    u8Arr[l] = bstr.charCodeAt(l)
  }
  return new Blob([u8Arr], {
    type: 'png'
  })
}

/**
 * blob转file
 * @param newBlob
 * @param fileName
 * @returns
 */
export function blobToFile(newBlob: any, fileName: string) {
  newBlob.lastModifiedDate = new Date()
  newBlob.name = fileName
  return newBlob
}
/**
 * 时间格式化
 * @param fmt
 * @param date
 * @returns
 */
export function dateFormat(fmt: string, date: Date) {
  const o: any = {
    'y+': date.getFullYear(),
    'M+': date.getMonth() + 1, //月份
    'd+': date.getDate(), //日
    'h+': date.getHours(), //小时
    'm+': date.getMinutes(), //分
    's+': date.getSeconds(), //秒
    'q+': Math.floor((date.getMonth() + 3) / 3), //季度
    S: date.getMilliseconds() //毫秒
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + '').substr(4 - RegExp.$1.length)
    )
  }
  for (const k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length)
      )
    }
  }
  return fmt
}
/**
 * 获取ur参数
 * @param variable
 * @returns
 */
export function getQueryVariable(variable: string) {
  const search =
    window.location.search.substr(1) || window.location.hash.split('?')[1] || ''
  const reg = new RegExp('(^|&)' + variable + '=([^&]*)(&|$)')
  const r = search.match(reg)
  if (r != null) return unescape(r[2])
  return null
}
/***
 * 他Zu安
 */
export function unitFormat(num: number, limit = 10000) {
  if (num < limit) {
    return Number(Math.floor(num).toFixed(1))
  }

  return (Math.floor((num / 10000) * 10) / 10).toFixed(1) + 'w'
}

/**
 * px转换为vw
 * @param css
 * @returns
 */
export function pxToVw(css?: string | number) {
  if (!css) return ''
  const px = Number(css)
  if (px) {
    return `${(px * 100) / 375}vw`
  } else {
    return String(css)
  }
}

export function countDist(
  lat1: number,
  lng1: number,
  lat2: number,
  lng2: number
) {
  //纬度1,经度1,纬度2,经度2

  const f = (((lat1 + lat2) / 2) * Math.PI) / 180.0

  const g = (((lat1 - lat2) / 2) * Math.PI) / 180.0

  const l = (((lng1 - lng2) / 2) * Math.PI) / 180.0

  let sg = Math.sin(g)

  let sl = Math.sin(l)

  let sf = Math.sin(f)

  const a = 6378137.0 //地球的直径

  const fl = 1 / 298.257

  sg = sg * sg

  sl = sl * sl

  sf = sf * sf

  const s = sg * (1 - sl) + (1 - sf) * sl

  const c = (1 - sg) * (1 - sl) + sf * sl

  const w = Math.atan(Math.sqrt(s / c))

  const r = Math.sqrt(s * c) / w

  const d = 2 * w * a

  const h1 = (3 * r - 1) / 2 / c

  const h2 = (3 * r + 1) / 2 / s

  const num = d * (1 + fl * (h1 * sf * (1 - sg) - h2 * (1 - sf) * sg))

  return num //返回单位:米
}

export function sec_to_time(s: number): string {
  let t = ''
  if (s > -1) {
    const hour = Math.floor(s / 3600)
    const min = Math.floor(s / 60) % 60
    const sec = s % 60

    if (hour === 0) {
      t = ''
    } else {
      t = hour + ':'
    }

    t += min + ':'
    if (sec < 10) {
      t += '0'
    }
    t += sec.toFixed(0)
  }
  return t
}

export function aesEncrypt(data: any) {
  const SECRET_KEY = CryptoJS.enc.Utf8.parse('gtD@s6Fh7pNU0WWP')
  const SECRET_IV = CryptoJS.enc.Utf8.parse('abcdefghijklmnop')

  const curData = typeof data == 'string' ? qs.parse(data) : Object.assign(data)
  curData.time = new Date().getTime()

  const dataHex = CryptoJS.enc.Utf8.parse(JSON.stringify(curData))
  console.log('待加密字符串', dataHex, typeof dataHex)
  const res = CryptoJS.AES.encrypt(dataHex, SECRET_KEY, {
    iv: SECRET_IV,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  })
  console.log('res', res)
  return res
}

export function randomList(arr: any[]) {
  for (let i = 0; i < arr.length; i++) {
    const randomIndex = Math.round(Math.random() * (arr.length - 1 - i)) + i
    const tmp: any = arr[i]
    arr[i] = arr[randomIndex]
    arr[randomIndex] = tmp
  }
  return arr
}

/**
 * RSA加密
 * @param data
 * @returns
 */
export function rsaEncrypt(data: any) {
  const encrypt = new JSEncrypt()
  encrypt.setPublicKey(PUBLIC_KEY)
  return encrypt.encrypt(data)
}

/**
 * 文本复制到剪贴板
 * @param text
 */
export async function copyTextToClipboard(text: string) {
  try {
    await navigator.clipboard.writeText(text)
    Toast('复制成功')
    console.log('Text copied to clipboard')
  } catch (err) {
    console.error('Failed to copy text: ', err)
  }
}
