/*
 * @Descripttion:
 * @Author: Yi Yunwan
 * @Date: 2020-09-04 18:12:45
 * @LastEditors: yaozuo
 * @LastEditTime: 2021-08-17 10:17:08
 */
import Vue from 'vue'
import alert_icon from '@/assets/kigo_icon/alert_icon.png'
import '@/styles/index.scss'
import {
  Toast,
  Image as VanImage,
  Icon,
  Uploader,
  Button,
  Dialog,
  NavBar,
  Overlay,
  ImagePreview,
  PullRefresh,
  List,
  Swipe,
  SwipeItem,
  Field,
  CellGroup,
  Loading,
  Checkbox,
  CheckboxGroup,
  DatetimePicker,
  Popup,
  NoticeBar,
  Badge,
  Skeleton,
  Popover,
  NumberKeyboard,
  Circle,
  Tab,
  Tabs,
  ActionSheet,
  Picker,
  DropdownMenu,
  DropdownItem,
  Radio,
  AddressList,
  AddressEdit,
  RadioGroup
} from 'vant'
Toast.setDefaultOptions({
  duration: 3000,
  //icon: 'https://qiniu.kigo.top/04fcd502651047e39d94816b2cf82f15',
  icon: alert_icon,
  className: 'kigo-toast',
  transition: 'kigo-toast-transition'
})
Vue.use(VanImage)
Vue.use(Popup)
Vue.use(DatetimePicker)
Vue.use(Toast)
Vue.use(Icon)
Vue.use(Uploader)
Vue.use(Button)
Vue.use(Dialog)
Vue.use(NavBar)
Vue.use(Overlay)
Vue.use(ImagePreview)
Vue.use(PullRefresh)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(List)
Vue.use(Field)
Vue.use(CellGroup)
Vue.use(Loading)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(NoticeBar)
Vue.use(Badge)
Vue.use(Skeleton)
Vue.use(Popover)
Vue.use(NumberKeyboard)
Vue.use(Circle)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(ActionSheet)
Vue.use(Picker)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Radio)
Vue.use(AddressList)
Vue.use(AddressEdit)
Vue.use(RadioGroup)
