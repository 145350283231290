/*
 * @Descripttion:
 * @Author: Yi Yunwan
 * @Date: 2020-12-03 14:22:02
 * @LastEditors: Yi Yunwan
 * @LastEditTime: 2021-03-04 15:46:57
 */
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {}
})
