/*
 * @Descripttion:
 * @Author: Yi Yunwan
 * @Date: 2020-09-11 14:14:41
 * @LastEditors: yaozuo
 * @LastEditTime: 2022-02-25 15:59:17
 */
import { CommonModule } from '@/store/modules/common'
import { compare, getPlatform } from '.'
import { gameJsBridge } from './gameBridge'

let connectWebViewJavascriptBridge: (
  callback: (bridge: Window['WebViewJavascriptBridge']) => void
) => void
if (window.location.href.indexOf('iOS') !== -1) {
  // iOS13 及其以上版本
  console.log('iOS13 及其以上版本')
  connectWebViewJavascriptBridge = function(callback) {
    if (getPlatform() === 'chekchek') {
      return callback(gameJsBridge)
    }
    console.log(
      'window.WebViewJavascriptBridge',
      window.WebViewJavascriptBridge
    )
    if (window.WebViewJavascriptBridge) {
      return callback(window.WebViewJavascriptBridge)
    }
    if (window.WVJBCallbacks) {
      return window.WVJBCallbacks.push(callback)
    }
    window.WVJBCallbacks = [callback]
    const WVJBIframe = document.createElement('iframe')
    WVJBIframe.style.display = 'none'
    WVJBIframe.src = 'wvjbscheme://__BRIDGE_LOADED__'
    document.documentElement.appendChild(WVJBIframe)
    setTimeout(function() {
      document.documentElement.removeChild(WVJBIframe)
    }, 0)
  }
  connectWebViewJavascriptBridge(function(bridge) {
    bridge.registerHandler('JS Echo', function(data, responseCallback) {
      console.log('JS Echo called with:', data)
      responseCallback(data)
    })
    bridge.callHandler('ObjC Echo', { key: 'value' }, function responseCallback(
      responseData
    ) {
      console.log('JS received response:', responseData)
    })
  })
} else {
  // 安卓 和 iOS13 以下及其版本
  console.log('安卓 和 iOS13 以下及其版本')
  connectWebViewJavascriptBridge = function(callback) {
    if (getPlatform() === 'chekchek') {
      return callback(gameJsBridge)
    }
    console.log(
      'window.WebViewJavascriptBridge',
      window.WebViewJavascriptBridge
    )

    if (window.WebViewJavascriptBridge) {
      callback(window.WebViewJavascriptBridge)
    } else {
      document.addEventListener(
        'WebViewJavascriptBridgeReady',
        function() {
          callback(window.WebViewJavascriptBridge)
        },
        false
      )
    }
  }
  connectWebViewJavascriptBridge(function(bridge) {
    bridge.init()
  })
}

let methodList: string[] = []

export async function callBridgeAsync(
  name: string,
  data: string | number | object = '',
  /**超时清除 */
  overtime = true,
  /**默认10s */
  overtimes = 10000
): Promise<any> {
  console.log('callBridgeAsync', {
    name: name,
    data: data
  })
  if (typeof data === 'object') {
    data = JSON.stringify(data)
  }
  return new Promise((resolve, reject) => {
    if (methodList.length) {
      if (!methodList.includes(name)) {
        return reject({
          code: '404',
          message: `应用异常：${name}未在宿主机注册`
        })
      }
    }

    const timer = overtime
      ? setTimeout(() => {
          console.log(name, '超时')
          reject('超时')
        }, overtimes)
      : 0
    connectWebViewJavascriptBridge(function(bridge) {
      bridge.callHandler(name, data, function(response) {
        if (typeof response == 'string') {
          try {
            response = JSON.parse(response)
          } catch (e) {
            console.log(`事件${name}响应为非json字符串`)
          }
        }
        if (process.env.NODE_ENV != 'production') {
          console.log(`事件${name}响应`, response)
        }
        overtime && clearTimeout(timer)
        if (response?.code && response.code != 200) {
          reject(response)
        }
        resolve(response)
      })
    })
  })
}

export async function callBridgeLongAsync(
  name: string,
  data: string | number | object = ''
) {
  if (typeof data === 'object') {
    data = JSON.stringify(data)
  }
  console.log('callBridgeLongAsync', {
    name: name,
    data: data
  })

  return new Promise((resolve, reject) => {
    if (methodList.length) {
      if (!methodList.includes(name)) {
        return reject({
          code: '404',
          message: `应用异常：${name}未在宿主机注册`
        })
      }
    }

    connectWebViewJavascriptBridge(function(bridge) {
      bridge.callHandler(name, data, function(response) {
        if (typeof response == 'string') {
          try {
            response = JSON.parse(response)
          } catch (e) {
            console.log(`事件${name}响应为非json字符串`)
          }
        }
        if (process.env.NODE_ENV != 'production') {
          console.log(`事件${name}响应`, response)
        }

        if (response?.code && response.code != 200) {
          reject(response)
        }
        resolve(response)
      })
    })
  })
}

export function registerBridge(name: string, callback: (res: any) => void) {
  console.log(`事件registerBridge`, name)
  connectWebViewJavascriptBridge(function(bridge) {
    console.log(`事件registerBridge bridge`, bridge)
    bridge.registerHandler(name, function(response) {
      console.log(`事件${name}触发`, response)
      callback(response)
    })
  })
}

function callBridge(name: string, data: string | object = '') {
  if (typeof data === 'object') {
    data = JSON.stringify(data)
  }
  connectWebViewJavascriptBridge(function(bridge) {
    bridge.callHandler(name, data, function(response) {
      console.log(`事件${name}响应`, response)
    })
  })
}
export function callAppFunc_IsHiddenNavagationBar() {
  callBridge('appFunc_IsHideNavBar')
}

/**
 * 获取基本信息
 */
export function callAppFunc_getSomeParams() {
  return callBridgeAsync('appFunc_getSomeParams') as Promise<any>
}

/**
 * 设置导航颜色
 * @param navColor 16进制颜色 #FFFFFF
 */
export function callAppFunc_setNavColor(data: { navColor: string }) {
  callBridge('appFunc_setNavColor', data)
}

/**
 * 主动获取token
 */
export function callAppFunc_CacheToken() {
  return callBridgeAsync('appFunc_CacheToken') as Promise<any>
}

/**
 * 设置导航文字颜色
 */
export function callAppFunc_setTitleFontColor(data: { navFontColor: string }) {
  return callBridgeAsync('appFunc_setTitleFontColor', data) as Promise<any>
}

/**
 * 调用登陆
 * @param type
 */
export function callAppFunc_Jump2Act() {
  return callBridgeAsync('appFunc_Jump2Act', '1') as Promise<any>
}

/**
 * 通知已签到
 */
export function callAppFunc_HasSignIn() {
  return callBridge('appFunc_HasSignIn')
}

/**
 * 下载
 */
export function callAppFunc_startToDownload(url: string) {
  return callBridgeAsync('appFunc_startToDownload', url)
}

/**
 * 下载
 */
export function callAppFunc_startDown(id: string) {
  return callBridgeAsync('appFunc_startDown', id)
}

/**
 * appFunc_dbSet
 */
export function callAppFunc_dbSet(data: { key: string; value: any }) {
  return callBridgeAsync('appFunc_dbSet', data)
}

/**
 * 打开第三方App
 */
export function callAppFunc_webToThirdApp(data: {
  /**
   * app包名
   */
  appPackageName: string
  /**
   * 若为true则只跳应用市场
   */
  allToAppStore?: boolean
  /**
   * 打开app携带的数据
   */
  appData?: any
}) {
  return callBridgeAsync('appFunc_webToThirdApp', data)
}
/**
 * 打开默认浏览器跳转到指定网址
 * @method 'appFunc_openBrowser'
 * @param url 指定网址
 * @returns
 */
export function callAppFunc_openBrowser(data: { url: string }) {
  return callBridgeAsync('appFunc_openBrowser', data)
}
/**
 * 预览图片
 * @param url 图片的url
 * @returns
 */
export function callAppFunc_previewImage(url: string) {
  return callBridgeAsync('appFunc_previewImage', url)
}
/**
 * 删除当前预览图片
 * @method 'h5Func_delPreviewImage'
 * @returns {url} 为删除图片的url
 */
export function callH5Func_delPreviewImage(callback: (url: string) => void) {
  registerBridge('h5Func_delPreviewImage', (url: string) => {
    callback(url)
  })
}

/**
 * 获取wxcode
 */
export function callAppFunc_getWXCode() {
  return callBridgeAsync('appFunc_getWXCode', '', false)
}

/**
 * 获取App注册的所有方法名
 */
export async function callAppFunc_getMethodList() {
  const res = await callBridgeAsync('appFunc_getMethodList')
  methodList = res.data.list
}

/**
 * 关闭当前webview弹窗
 */
export function closeWebViewPopUp() {
  return callBridgeAsync('appFunc_closeWebViewPopUp')
}

/**
 * 每日任务下列表按钮点击事件跳转到
 * String
 * 1	直播热门页
 * 2	话题动态发布页
 * 3	广场热门页
 */
export function appFunc_TaskCenter(val: any) {
  return callBridgeLongAsync('appFunc_TaskCenter', val)
}

/**新手任务跳转到编辑个人信息页 */
export function appFunc_ToEditUserData() {
  return callBridgeLongAsync('appFunc_jumpToEditUserData')
}
/**新手任务5在当前页跳转至充值页 参数？*/
export function appFunc_JumpNewWeview(url: string) {
  return callBridgeLongAsync('appFunc_JumpNewWeview', url)
}
/**
 * 跳转至用户个人中心
 * @param uid
 */
export function callAppFunc_jumpToPersonalCenter(uid: number | string) {
  return callBridgeAsync('appFunc_jumpToPersonalCenter', String(uid))
}
export function callAppFunc_openPersonalDataPopUp(uid: number | string) {
  return callBridgeAsync('appFunc_openPersonalDataPopUp', String(uid))
}

/**
 * 充值
 * @param data
 */
export function callAppFunc_JumpIosPay(data: any) {
  return callBridgeLongAsync('appFunc_JumpIosPay', data)
}

/**
 * 打开微信
 * @param weChat
 */
export function callAppFunc_OpenWeChat(weChat: string) {
  return callBridgeAsync('appFunc_OpenWeChat', weChat)
}
/**
 * 复制
 * @param str
 */
export function callAppFunc_copyMess(str: string) {
  return callBridgeAsync('appFunc_copyMess', { str })
}

export function callAppFunc_openApp(data: {
  appPackageName: string
  schemeUrl: string
  appDownloadUrl: string
}) {
  return callBridgeAsync('appFunc_openApp', data)
}
export function callAppFunc_closeWebview() {
  return callBridgeAsync('appFunc_closeWebview')
}

export function callAppFunc_statusBarHeight() {
  return callBridgeAsync('appFunc_statusBarHeight')
}
/***
 * 跳转房间管理
 */
export function callAppFunc_jumpToRoomManage() {
  return callBridgeAsync('appFunc_jumpToRoomManage')
}
/**
 * 打开礼物/粉丝团/背包弹窗
 * 判断兼容性 2.5.1
 * @returns
 */
interface GiftFansBackpackProps {
  tabbarId?: number
  giftType?: number
  giftId?: number
}

export function callAppFunc_openGiftFansBackpackPopUp(
  appVersion: string,
  data: GiftFansBackpackProps = {
    tabbarId: 1,
    giftType: 1,
    giftId: 1
  }
) {
  const usable = compare(appVersion, '2.5.1')

  if (usable) {
    return callBridgeAsync('appFunc_openGiftFansBackpackPopUp', data)
  }

  let name = 'appFunc_jumpToBackpackPopUp'
  if (data.tabbarId == 1) {
    name = 'appFunc_jumpToGiftPopUp'
  } else if (data.tabbarId == 2) {
    name = 'appFunc_jumpToFansGroupGiftPopUp'
  }

  return data.giftId
    ? callBridgeAsync(name, {
        id: data.giftId
      })
    : callBridgeAsync(name)
}

export function h5Func_webActivity() {
  return callBridgeAsync('appFunc_jumpToRoomManage')
}

/**
 * 导航图标点击回调
 * @returns callback()
 */
export function callh5Func_webActivity(callback: (res: any) => void) {
  registerBridge('h5Func_webActivity', (res: any) => {
    callback(res)
  })
}
/**
 * 跳转至个人开播页，在开播页点击【返回】按钮需返回至当前页
 * @returns
 */
export function callAppFunc_jumpToStartLive() {
  return callBridgeAsync('appFunc_jumpToStartLive')
}
/**
 * 跳转至直播间/聊天室
 * @param data
 * @param roomId   房间id
 * @param fromType 点击来源 默认为0
 * @returns
 */
export function callAppFunc_jumpToLiveRoom(data: {
  roomId: string | number
  fromType: number
}) {
  let version: number | string = CommonModule.appVersion
  console.log('version:', version)

  if (version) {
    version = Number(version.replace(/\./g, ''))
    if (version < 260) {
      return callBridgeAsync('appFunc_jumpToLiveRoom', data.roomId)
    }
  }
  return callBridgeAsync('appFunc_jumpToLiveRoom', data)
}
/**
 * 打开系统相册
 * @returns
 */
export function callAppFunc_openSystemAlbum() {
  return callBridgeAsync('appFunc_openSystemAlbum')
}
/**
 * 选择图片成功
 * @returns base64
 * 点击取消不用调用
 */
export function callH5Func_choiceImageSuccess(
  callback: (base64: string) => void
) {
  registerBridge('h5Func_choiceImageSuccess', (base64: string) => {
    callback(base64)
  })
}
/**
 * 客户端支付成功回调
 * @returns
 * {
 *   result 0成功 失败-1
 *   chargeType 1:金币充值 2 周卡月卡 3 每日礼包
 * }
 */
export function callH5Func_nativePayResult(
  callback: (data: any) => void
) {
  registerBridge('h5Func_nativePayResult', (data: any) => {
    callback(data)
  })
}
/**
 * 跳转至实名认证
 * @returns
 */
export function callAppFunc_jumpToRealNameVerify(verifyStatus: number) {
  return callBridgeAsync('appFunc_jumpToRealNameVerify', verifyStatus)
}
/**
 * 隐藏/显示导航栏
 * @param status 0-隐藏 1-显示
 * @returns
 */
export function callAppFunc_showHideNavigationBar(params: { status: number }) {
  return callBridgeAsync('appFunc_showHideNavigationBar', params)
}
/**
 * 隐藏/显示底部导航栏
 * @param status 0-隐藏 1-显示
 * @returns
 */
export function callAppFunc_showHideBottomNavigationBar(params: {
  status: number
}) {
  return callBridgeAsync('appFunc_showHideBottomNavigationBar', params)
}
/**
 * 关闭当前webview（导航栏的关闭按钮）
 * @returns
 */
export function callAppFunc_closeCurrentWebview() {
  return callBridgeAsync('appFunc_closeCurrentWebview')
}
/**
 *
 * @returns 关闭当前 webview 弹窗
 *
 */
export function callAppFunc_closeWebViewPopUp() {
  return callBridgeAsync('appFunc_closeWebViewPopUp')
}
/**
 * 隐藏导航栏关闭按钮
 * @returns
 */
export function callAppFunc_hideNavCloseBtn() {
  return callBridgeAsync('appFunc_hideNavCloseBtn')
}
/**
 * IOS-钱包是否显示充值弹窗
 * app接受到消息后表示h5已弹过充值弹窗
 * @returns
 */
export function callAppFunc_useRechargePopUp() {
  return callBridgeAsync('appFunc_useRechargePopUp')
}
/**
 * IOS-获取钱包充值弹窗状态
 * 先调用 判断status=0的时候再调用 callAppFunc_useRechargePopUp()
 * @param
 * @returns { status : 0 } 0-app未弹起 1-app已弹
 */
export function callAppFunc_getRechargePopUpStatus() {
  return callBridgeAsync('appFunc_getRechargePopUpStatus')
}
/**
 * 打开客服会话
 * @returns {id} 指定客服ID
 */
export function callAppfunc_customerServiceConversation(params: {
  id: string | number
}) {
  return callBridgeAsync('appfunc_customerServiceConversation', params)
}
/***
 * 显示导航栏右上角图标
 * @param {name}  'share-分享 wallet-钱包明细'
 */
export function callAppFunc_showNavBtn(
  params: Array<{ name: string; iconUrl: string }>
) {
  return callBridgeAsync('appFunc_showNavBtn', params)
}
/**
 * 点击导航栏图标回调
 * @param callback
 */
export function callh5Func_navBtnClick(callback: any) {
  registerBridge('h5Func_navBtnClick', res => {
    callback(res)
  })
}
/**
 * 社交模块任务跳转事件
 * String
 * 1	同城的人
 * 2	聊天匹配
 * 3	消息页
 */
export function appFunc_JumpForSocialTask(val: any) {
  return callBridgeAsync('appFunc_JumpForSocialTask', val)
}

/**
 * 领取隐藏任务奖励后通知app
 * @returns
 */
export function appFunc_getHideTaskReward() {
  return callBridgeAsync('appFunc_getHideTaskReward')
}

/**
 * 进入访客任务页面后通知app
 * @returns
 */
export function appFunc_visitorTaskPageLoad() {
  return callBridgeAsync('appFunc_visitorTaskPageLoad')
}

/**
 * 调用分享
 * @returns
 */
export function callAppFunc_WebShare(params: any) {
  return callBridgeAsync('appFunc_WebShare', params) as Promise<any>
}

/**
 * 返回app首页
 * @returns
 */
export function callAppFunc_goHome() {
  return callBridge('appFunc_goHome')
}

/**
 * 注销成功
 * @returns
 */
export function callAppFunc_logOffSuccess() {
  return callBridge('appFunc_logOffSuccess')
}
/**
 * 通知移动端vip支付成功
 * @returns
 */
export function callAppFunc_onH5VipPaySuccess() {
  return callBridgeAsync('appFunc_onH5VipPaySuccess')
}

/**
 * 设置导航栏返回按钮图标
 * @param data
 * @returns
 */
export function callAppFunc_setNavBackIcon(data: { backIconUrl: string }) {
  return callBridge('appFunc_setNavBackIcon', data)
}
/**
 * 设置导航栏关闭按钮图标
 * @param data
 * @returns
 */
export function callAppFunc_setNavCloseIcon(data: { closeIconUrl: string }) {
  return callBridge('appFunc_setNavCloseIcon', data)
}
/**
 * 钱包支付成功
 * @param data
 * @returns
 */
export function callappFunc_onH5PaySuccess(data: { money: number }) {
  console.log('callappFunc_onH5PaySuccess', data)
  return callBridge('appFunc_onH5PaySuccess', data)
}

/**
 * 设置导航栏蒙板
 * @param data
 * @returns
 */
export function callAppFunc_setNavMask(data: { isNavMaskShow: 0 | 1 }) {
  return callBridge('appFunc_setNavMask', data)
}
/**
 * 跳转至用户个人主页
 * @param data
 * @returns
 */
export function callAppFunc_jumpToUserHome(data: { uid: string }) {
  return callBridge('appFunc_jumpToUserHome', data)
}

/**
 * ios震动
 * @returns
 */
export function callAppFunc_exploreGameVibrationDialog() {
  return callBridge('appFunc_exploreGameVibrationDialog')
}

/**
 * 隐藏/显示手机状态栏
 * @param status 0-隐藏 1-显示
 * @returns
 */
export function callAppFunc_showHideStatusBar(params: { status: number }) {
  return callBridgeAsync('appFunc_showHideStatusBar', params)
}

/**
 * 打开星球探索商店弹窗
 * @returns
 */
export function callAppFunc_exploreGameStoreDialog(currentPage = 1) {
  return callBridge('appFunc_exploreGameStoreDialog', { currentPage })
}

/**
 * 登录成功回调
 * @param callback
 */
export function callAppFunc_loginStateChanged(callback: any) {
  registerBridge('appFunc_loginStateChanged', res => {
    callback(res)
  })
}

/**
 * ios跳转原生钱包
 * @returns
 */
export function callAppFunc_GoWallet() {
  return callBridge('appFunc_GoWallet')
}

/**
 * 获取微信授权
 * @returns
 */
export function callAppFunc_bindWechat() {
  return callBridge('appFunc_bindWechat')
}

/**
 * 微信授权回调
 * @param callback
 */
export function callH5Func_wechatCode(callback: any) {
  registerBridge('h5Func_wechatCode', res => {
    callback(res)
  })
}

/**
 * 领取任务奖励通知移动端
 * @returns
 */
export function callAppFunc_receiveTaskCallBack() {
  return callBridge('appFunc_receiveTaskCallBack')
}

/**
 * app跳转到房间列表
 * @param type
 * @returns
 */
export function callAppFunc_goRoomList(type: number) {
  return callBridge('appFunc_goRoomList', { type })
}

/**
 * 拉起app版本更新弹窗
 * @returns
 */
export function callAppFunc_checkVersion(data: any) {
  console.log('callAppFunc_checkVersion')
  return callBridge('appFunc_checkVersion', data)
}

/**
 * 拉起app微信支付 购买月卡周卡
 * @returns
 */
export function callAppFunc_onMonthCardWechatPay(data: any) {
  console.log('appFunc_onMonthCardWechatPay', data)
  return callBridge('appFunc_onMonthCardWechatPay', data)
}
/**
 * 拉起app微信支付 购买每日礼包
 * @returns
 */
export function callAppFunc_onDailyPackageWechatPay(data: any) {
  console.log('appFunc_onDailyPackageWechatPay', data)
  return callBridge('appFunc_onDailyPackageWechatPay', data)
}
/**
 * 拉起app微信支付 金币充值
 * @returns
 */
export function callAppFunc_onCoinsChargeWechatPay(data: any) {
  console.log('appFunc_onCoinsChargeWechatPay', data)
  return callBridge('appFunc_onCoinsChargeWechatPay', data)
}