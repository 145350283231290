/*
 * @Descripttion: * @Author: Yi Yunwan
 * @Date: 2020-09-04 17:14:37
 * @LastEditors: Yi Yunwan
 * @LastEditTime: 2021-07-21 17:48:55
 */
//export const javaBaseURL = 'http://japidev.kigo.top/'

//export const baseURL = 'http://papinewdev.kigo.top/'
// export const oldBaseURL = 'http://www.taktak.tv'

export const baseURL = window.globalKigoConfig.baseURL

export const javaBaseURL = window.globalKigoConfig.javaBaseURL
//export const oldBaseURL = window.globalKigoConfig.oldBaseURL
export const oldBaseURL = 'window.globalKigoConfig.oldBaseURL'

export const devToken = ''
export const webBaseURL =
  process.env.NODE_ENV === 'production'
    ? location.origin
    : 'https://webdev.kigo.top/'

export const reportConfig = {
  appKey: 'a8cec57d1cd5d0f2',
  baseURL: window.globalKigoConfig.reportBaseURL,
  debug: window.TAKTAK_DEBUG
}

export const IMConfig = {
  sdkAppId: window.globalKigoConfig.IMSdkAppId,
  Key: window.globalKigoConfig.IMKey,
  level: 4
}

export const SECRET_KEY = 'y3j1ju48uazcd51b9h56sftrkbebsuqt'

export const WX_APPID = window.globalKigoConfig.WX_APPID

/**
 *  app版本号
 */
export const APP_VERSION = '1.0.0'

export const apiMock =
  process.env.NODE_ENV !== 'production' && location.href.includes('mock=true')
export const mockBaseURL = process.env.VUE_APP_MOCK_BASE_API
export const PUBLIC_KEY =
  'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA08Tn+xMe896+87zfGbdac40z221Ndl9G1U3tVyqfbeIe1lhW513aBIW6qkVvsClil+UHK94gm8QYJXt0yyCn1TQW86GnsNihAmv984dGiIxFeluWovjpSNhGmUE98ZjNpqF2WK2ph7gOdDYPfPigZaLFL2C1fpzyefkY/BQE7NVsKYkSItrMoWz/je23ha+sUPO+4WAiuYDH/xp0gMDQHUMyrKTzjalQgjd6iQnWNl/85zucZSJOKI5ACDPsExKIrhgq9REjbC1OhFgX9dA+KTg8jC5xSQFeybsBkCvi+krrd+SQNSN+Y8B7b8H4MlgHf5GCl7pbfQygvK8mA3K8QwIDAQAB'
export const WXAPPID_IOS = 'wxe5e712f469d570e9'
export const WXSECRET_IOS = '04e7a1ce786b4f42afda139e2a16ad9c'
export const WXAPPID_ANDRID = 'wxe5e712f469d570e9'
export const WXSECRET_ANDRID = '04e7a1ce786b4f42afda139e2a16ad9c'
