/*
 * @Descripttion: 所有vue页面均需导入使用的基础文件
 * @Author: Yi Yunwan
 * @Date: 2021-03-01 16:34:02
 * @LastEditors: Yi Yunwan
 * @LastEditTime: 2021-04-23 10:27:24
 */
import 'normalize.css'
import '@/styles/index.scss'
import '@/vant'
import Vue from 'vue'
import bus from '@/common/utils/bus'
// import * as Sentry from '@sentry/vue'
// import { Integrations } from '@sentry/tracing'
import { getPlatform, useDebug } from './utils'
import { point } from '@/common/utils/point'
import { report } from '@/common/mixins/directive'
import { pageReport } from '@/common/mixins/mixins'

// if (process.env.NODE_ENV === 'production') {
//   Sentry.init({
//     Vue,
//     dsn: 'http://a10bf4b95def45d0a944669bdc932b55@139.155.28.29:9000/2',
//     autoSessionTracking: true,
//     integrations: [new Integrations.BrowserTracing()],
//     tracesSampleRate: 1.0,
//     environment: process.env.NODE_ENV
//   })
// }
Vue.config.productionTip = false
Vue.prototype.$platform = getPlatform()
Vue.prototype.$bus = bus
Vue.prototype.$debug = useDebug()
export { Vue, point, report, pageReport }
