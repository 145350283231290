/*
 * @Descripttion: 埋点文件
 * @Author: Yi Yunwan
 * @Date: 2020-09-17 14:22:03
 * @LastEditors: Yi Yunwan
 * @LastEditTime: 2021-06-04 10:39:55
 */

import { getNetworkType, getSystemInfo } from './device'
import axios from 'axios'

import { encode, getPlatform, guid } from './index'
import { reportConfig } from '@/config'
import qs from 'qs'
import { CommonModule } from '@/store/modules/common'
const http = axios.create({
  baseURL: reportConfig.baseURL,
  timeout: 100000
})
http.interceptors.request.use(config => {
  config.data = qs.stringify(config.data)
  return config
})

function sendXHR(url: string, data: any) {
  const xmlHttp = new XMLHttpRequest()
  xmlHttp.open('POST', url, false)
  xmlHttp.send(data)
}

function sendData(url: string, data: any) {
  const _data = qs.stringify(data)
  navigator.sendBeacon ? navigator.sendBeacon(url, _data) : sendXHR(url, _data)
}

/**
 * 埋点sdk
 */
export class Point {
  constructor(options: PointOptions) {
    const sysInfo = getSystemInfo()

    const commonInfo: CommonInfo = {
      appKey: options.commonInfo.appKey || options.appKey,
      sdkVersion: '1.1',
      randomId: guid(),
      device: sysInfo.model || '',
      deviceType: sysInfo.platform,
      system: sysInfo.system.split(' ')[0],
      systemVersion: sysInfo.system.split(' ')[1],
      network: getNetworkType(),
      ip: window.returnCitySN?.cip || '',
      cityAddr: window.returnCitySN?.cname || '',
      userId: CommonModule.userId,
      deviceId: CommonModule.deviceId,
      deviceIdV2: CommonModule.deviceId,
      userType: '',
      appVersion: CommonModule.appVersion,
      appChannel: '',
      operators: '',
      lang: ''
    }
    this.reportData = Object.assign({}, this.reportData, {
      type: options.appKey
    })
    this.reportData.data = Object.assign({}, this.reportData.data)
    this.reportData.data.commonInfo = Object.assign(
      {},
      this.reportData.data.commonInfo,
      commonInfo,
      options.commonInfo
    )
    options.config &&
      (this.config = Object.assign({}, this.config, options.config))
    options.eventMap && this.init(options.eventMap)
  }

  private config!: ReportConfig

  private reportData!: ReportData

  /**
   * sdk初始化
   * @param eventMap
   */
  init(eventMap: PageEventMap) {
    this.pageReport(eventMap)
  }

  /**
   * 数据合并和加密
   * @param eventMap
   */
  assinReportData(eventMap: ClickEventMap | PageEventMap) {
    // 复制一个上报数据实例
    const reportData: ReportData = Object.assign({}, this.reportData)
    // 合并 eventMap
    reportData.data = Object.assign(
      {
        eventMap
      },
      reportData.data
    )
    // 需重新获取的数据重新获取
    const commonInfo: Partial<CommonInfo> = {
      network: getNetworkType(),
      userId: CommonModule.userId,
      deviceId: CommonModule.deviceId,
      deviceIdV2: CommonModule.deviceId,
      userType: CommonModule.userType,
      appVersion: CommonModule.appVersion
    }
    // 合并公用字段
    reportData.data.commonInfo = Object.assign(
      {},
      reportData.data.commonInfo,
      commonInfo
    )
    reportData.data.dataMap = {
      ...reportData.data.eventMap.dataMap,
      page_from: getPlatform()
    }
    delete reportData.data.eventMap.dataMap
    if (this.config?.debug) {
      console.log('数据：', reportData.data)
    }
    // 加密返回
    return {
      type: reportData.type,
      data: encode(JSON.stringify(reportData.data))
    }
  }

  /**
   * 点击上报事件
   * @param eventMap
   */
  clickReport(eventMap: ClickEventMap, sync = false) {
    console.log('点击大数据上报数据:', eventMap)
    eventMap.clientTime = new Date().getTime()
    eventMap.targetId = eventMap.targetId || '0'
    eventMap.targetName = eventMap.targetName || '0'
    eventMap.dataMap = eventMap.dataMap || {}

    const data = this.assinReportData(eventMap)
    if (sync) {
      sendData(`${this.config.baseURL}/click`, data)
      return
    }

    http.request({
      baseURL: this.config.baseURL,
      url: '/click',
      method: 'POST',
      data
    })
  }

  /**
   * 页面上报事件
   * @param eventMap
   */
  pageReport(eventMap: PageEventMap, sync = false) {
    eventMap.getDataTime = new Date().getTime()
    eventMap.dataMap = eventMap.dataMap || {}
    console.log('页面大数据上报数据：', eventMap)

    const data = this.assinReportData(eventMap)
    if (sync) {
      sendData(`${this.config.baseURL}/page`, data)
      return
    }

    http.request({
      baseURL: this.config.baseURL,
      url: '/page',
      method: 'POST',
      data
    })
  }
}

export const point = new Point({
  appKey: reportConfig.appKey,
  commonInfo: {},
  config: {
    baseURL: reportConfig.baseURL,
    debug: reportConfig.debug
  }
})
