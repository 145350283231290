/*
 * @Descripttion: 接口请求相关
 * @Author: Yi Yunwan
 * @Date: 2020-09-04 17:13:23
 * @LastEditors: Deng
 * @LastEditTime: 2021-07-15 16:36:51
 */
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import {
  baseURL,
  javaBaseURL,
  oldBaseURL,
  SECRET_KEY,
  apiMock,
  mockBaseURL
} from '@/config'
import { Toast } from 'vant'
import { CommonModule } from '@/store/modules/common'
import qs from 'qs'
import md5 from 'js-md5'
import { aesEncrypt, getAppLanguage } from '@/common/utils'
import { isIOS } from '@/common/utils'
import { callAppFunc_checkVersion } from '@/common/utils/bridge'
export function stringifyData(config: AxiosRequestConfig) {
  config.data = qs.stringify(config.data)
  return config
}
const language = getAppLanguage()
export function _log(response: AxiosResponse<any>) {
  if (process.env.NODE_ENV === 'development') {
    console.group()
    console.log('*==== 请求信息 ====*')
    console.log('地址：', response.config.method, response.config.url)
    const data = response.config.data
    const params = response.config.params

    if (data && JSON.stringify(data) != '"{}"') {
      Object.keys(data).length > 0 && console.log('请求参数：', data)
    }
    if (params && JSON.stringify(params) != '"{}"') {
      Object.keys(params).length > 0 && console.log('QUERY参数：', params)
    }
    console.log('响应结果：', response.data)
    console.log('响应配置：', response.config)
    console.log('-------------------------------------------')
    console.groupEnd()
  }
}

export function addSign(config: AxiosRequestConfig) {
  const timestamp = Math.floor(new Date().getTime() / 1000)
  const sign = md5(md5(SECRET_KEY) + timestamp)
  config.headers['timestamp'] = timestamp
  //config.headers['sign'] = sign
  config.headers['app-version'] = CommonModule.appVersion
  config.headers['device-id'] = CommonModule.deviceId
  config.headers['request-from'] = CommonModule.requestFrom
  if (!config.headers['app-front']) {
    config.headers['app-front'] = 1
  }
  config.headers['channel'] = CommonModule.channel
  //config.headers['platform'] = isIOS ? 'ios' : 'android'
  const encryp = aesEncrypt(
    config.data ? config.data : config.params ? config.params : {}
  )
  config.headers['sign'] = encryp.toString()
  config.headers['language'] = language == 'uyghur' ? 'ug' : 'cn'
  return config
}
export function addJavaSign(config: AxiosRequestConfig) {
  const timestamp = Math.floor(new Date().getTime() / 1000)
  const sign = md5(md5(SECRET_KEY) + timestamp)
  config.headers['timestamp'] = timestamp
  //config.headers['sign'] = sign
  config.headers['app-version'] = CommonModule.appVersion
  config.headers['device-id'] = CommonModule.deviceId
  config.headers['request-from'] = CommonModule.requestFrom
  if (!config.headers['app-front']) {
    config.headers['app-front'] = 1
  }
  config.headers['channel'] = CommonModule.channel
  config.headers['platform'] = isIOS ? 'ios' : 'android'
  const encryp = aesEncrypt(
    config.data ? config.data : config.params ? config.params : {}
  )
  config.headers['sign'] = encryp.toString()
  config.headers['language'] = language == 'uyghur' ? 'ug' : 'cn'
  return config
}
export function oldAddSign(config: AxiosRequestConfig) {
  if (!config.headers['app-front']) {
    config.headers['app-front'] = 1
  }
  config.headers['app-version'] = CommonModule.appVersion
  config.headers['channel'] = CommonModule.channel
  return config
}

export function serviceFulfilled(response: AxiosResponse) {
  // _log(response)
  // 对响应数据做点什么
  const data = response.data as IResponse
  if (response.status === 200) {
    if (data.code == '200') {
      return response.data
    } else if (data.code == '100003') {
      Toast(data.msg)
    } else if (data.code == '6001') {
      console.log('code 6001')
      callAppFunc_checkVersion(data.data)
    } else if (data.code && data.code != '1001') {
      Toast(data.msg || '服务器异常')
    }
    if (!data.code) {
      return response.data
    }
    return Promise.reject(data)
  } else {
    return Promise.reject(response)
  }
}

export function serviceRejected(error: Error) {
  // 对响应错误做点什么
  console.error(error)
  const errText =
    language == 'zh_CN' ? '网络请求失败，请重试' : 'tor ulanmidi， kayta sinag'
  error.message &&
    Toast(error.message == 'Network Error' ? errText : error.message)
  return Promise.reject(error)
}

const service = axios.create({
  baseURL,
  timeout: 100000
})
service.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded'
function addAuthorization(config: AxiosRequestConfig) {
  const Authorization = CommonModule.token
  Authorization && (config.headers['Authorization'] = Authorization)
  return config
}

export function useMock(config: AxiosRequestConfig) {
  if (apiMock) {
    config.baseURL = mockBaseURL
  }
  return config
}

service.interceptors.request.use(addAuthorization)
service.interceptors.request.use(addSign)
service.interceptors.request.use(useMock)
service.interceptors.response.use(serviceFulfilled, serviceRejected)

export { service }

export const javaService = axios.create({
  baseURL: javaBaseURL,
  timeout: 60 * 1000
})
javaService.interceptors.request.use(addJavaSign)
javaService.interceptors.request.use(addAuthorization)
javaService.interceptors.response.use(serviceFulfilled, serviceRejected)
