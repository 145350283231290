/*
 * @Descripttion:
 * @Author: Yi Yunwan
 * @Date: 2021-03-29 15:33:59
 * @LastEditors: yaozuo
 * @LastEditTime: 2021-10-28 11:54:31
 */

import { isIOS } from '@/common/utils/device'
import { service, javaService } from '@/common/utils/request'
import { APP_VERSION } from '@/config'
import { CommonModule } from '@/store/modules/common'
import qs from 'qs'
/**
 * 获取贡献榜列表
 */
export function getContributionList(
  params: GetContributionListParams
): IRes<{
  list: ContributionListInfo[]
}> {
  return service.get('/contribution/list', {
    params
  })
}

/**
 * 获取守护榜列表
 */
export function getGuardList(
  params: any
): IRes<{
  list: GuardListInfo[]
  /**
   * 0.不是 1.是
   */
  is_guard: 0 | 1
  /**
   * 	守护结束时间
   */
  end_time: string
  /**
   * 守护类型 1.月 2.年
   */
  guard_type: string
}> {
  return service.get('/guard/list', {
    params
  })
}

/***
 * 获取用户/主播等级信息
 */
export function getUserLevel(type: number) {
  return service.get('/api/user/level', { params: { type } })
}
/***
 * 获取超值礼包
 */
export function getGiftBag(user_id: number) {
  return service.get('/api/gift/bag/list', {
    params: {
      user_id: user_id,
      device_type: isIOS ? 2 : 3
    }
  })
}
/***
 * 获取等级特权
 */
export function getPrivilege() {
  return service.get('/api/user/level/privilege')
}

/**
 * 账户注销
 * @returns
 */
export function submitNullfiy() {
  return service.post('/api/user/logout/submit')
}
/**
 * 上传图片
 * @param data
 * @returns
 */
export function uploadImage(data: FormData) {
  return service.post('api/v1/5/upload/file', data)
}

/**
 * 提交反馈
 * @param params
 * @returns
 */
export function submitFeedback(params: {
  model: string
  images: string
  content: string
}) {
  return service.post('/api/help/feedback/submit', qs.stringify(params), {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 获取封面标签列表
 * @returns
 */
export function getLabelList() {
  return service.get('/api/cover/label/list')
}

/**
 * 封面标签-装备、卸载
 * @param params
 * @returns
 */
export function wearLabel(params: {
  id: string
  status: number //0卸载，1装备
}) {
  return service.post('/api/cover/label/wear', params)
}
/**
 * 问题分类列表
 * @returns
 */

/**
 * 问题列表
 * @returns
 */
export function helpProblemList(classId: string | (string | null)[]) {
  return service.get('/api/help/problem/list', {
    params: {
      class_id: classId
    }
  })
}
/**
 * 问题详情
 * @returns
 */
export function helpProblemDetail(id: string) {
  return service.get('/api/help/problem/detail', {
    params: {
      id
    }
  })
}
/**
 * ios 支付配置
 * @returns
 */
export function getIosConfig() {
  return service.get('/api/ios/pay/config')
}
/**
 * 礼物墙
 * @returns
 */
export function getGiftWall(params: { touid: string | (string | null)[] }) {
  return service.get('/achievement/gift/wall', { params })
}
/**
 * 勋章墙
 * @returns
 */
export function getMedalWall(params: { touid: string | (string | null)[] }) {
  return service.get('/achievement/medal/wall', { params })
}

/**
 * 礼物墙-礼物收集排行数据
 * @returns
 */
export function getGiftWallRank(params: {
  touid: string | (string | null)[]
  gift_id: string
}) {
  return service.get('/achievement/gift/collection/send/list', { params })
}
/***
 * 获取贵族默认数据
 */
export function getNoblePowerInfo(params: {
  uid: string | number
  phone_type: number
}) {
  return service.get('/api/v1/4/user/noble/power/info', { params })
}

/**
 * 切换贵族页信息
 * @returns
 */
export function getNoblePowerChange(params: {
  from?: number
  uid: string | number
  phone_type: number
  noble_id: number | string
}) {
  return service.get('/api/v1/4/user/noble/power/change', { params })
}
/**
 * 获取商品列表
 * @param params
 * @returns
 */
export function getNoblePowerCharge(params: {
  from?: number
  uid: string | number
  phone_type: number
  noble_id: number | string
}) {
  return service.get('/api/v1/4/user/noble/power/charge', { params })
}
/**
 * 我的贵族T钻明细
 * @returns
 * @param {string} touid 被查看的用户uid，若不填写就是当前用户的uid
 */
export function getNobleDetailLists(params: {
  touid: string | number
  page: number
  page_size: number
}) {
  return service.get('/api/v1/4/user/noble/detail/lists', { params })
}
/**
 * 用户开通之后返回弹出信息
 * @returns
 */
export function getNoblePowerSuccess(params: { uid: string | number }) {
  return service.get('/api/v1/4/user/noble/power/succ', { params })
}
/**
 * T钻充值
 * @param params
 * @returns
 */
export function nobleCoinPay(params: {
  uid: string | number
  charge_id: string
  phone_type: number
  coin: string | number
  num: number
  pay_type: number
}) {
  return service.get('/api/v1/4/user/noble/coin/pay', { params })
}
/**
 * 主播每日直播概况
 * @returns
 */
export function getLiveRecord(params: { date: string }): any {
  return service.get('/api/daily/live/info', { params })
}

//获取问题列表
export function getHelpList() {
  return service.get('/api/kigo/help/class/list')
}
//获取充值列表
export function getChargeList(uid?: string, front?: number) {
  return service.get(`/api/kigo/charge/list?uid=${uid}`, {
    headers: { 'app-front': front ? 1 : isIOS ? 2 : 3 }
  })
}

//设置充值软件
export function changChargeSet(data: { charge: number }) {
  return service.post('/api/kigo/charge/choose/set', qs.stringify(data))
}

/**
 * 获取充值明细
 * @param params
 * @returns
 */
export function getRechargeList(params: {
  uid: string | number
  token: string
}) {
  return service.get('/api/kigo/charge/record/list', { params })
}

/**
 * 获取富文本内容
 * @param params
 */
export function getRichTextContent(params: { id: string }) {
  return javaService.get('/kigo-user-api/protocol/getInfoById', { params })
}

/**
 * 抽奖
 * @param params
 * @returns
 */
export function getDrawLuckey(params: { uid: string }) {
  return javaService.get('/kigo-user-api/luck/draw', { params })
}

/**
 * 获取金豆和银豆兑换规则
 * @returns
 */
export function getExchangeRuleList() {
  return javaService.get('/kigo-room-api/kCoin/getExchangeRuleList')
}

/**
 * 兑换金豆或者银豆
 * @param params
 * @returns
 */
export function exchangeKCoinAndKBean(data: {
  exchangeRuleId: string | number
  uid: string | number
}) {
  return javaService.post(
    '/kigo-room-api/kCoin/exchangeKCoinAndKBean',
    qs.stringify(data),
    {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    }
  )
}

/**
 * 获取充值兑换明细记录列表
 * @param params
 * @returns
 */
export function getCHageRecordList(params: { page: number; limit: number }) {
  return javaService.get('/kigo-user-api/pay/getChargeRecordList', { params })
}

/**
 * 获取狼人杀对战记录列表
 * @param params
 * @returns
 */
export function getWolfKillFightRecordList(params: {
  page: number
  limit: number
}) {
  return javaService.get('/kigo-room-api/wolf/kill/getFightRecordList', {
    params
  })
}

/**
 * 取狼人杀战绩详情
 * @param params
 * @returns
 */
export function getWolfKillReocordDetail(params: { wolfRecordId: string }) {
  return javaService.get('/kigo-room-api/wolf/kill/getFightRecordDetail', {
    params
  })
}
