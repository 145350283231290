;(function() {
  const JSMath = function() {
    return this
  }

  JSMath.prototype.from = function(value) {
    // 支持JSMath参数传递主要是用于嵌套的调用
    if (typeof value == 'object' && value.value != undefined) {
      this.value = value.value
    } else {
      this.value = value
    }
    return this
  } // 加法

  JSMath.prototype.add = function(value) {
    const thisValueString = this.value.toString()
    const valueString = value.toString()
    let timesCount1 = 0
    let timesCount2 = 0
    if (thisValueString.indexOf('.') > 0) {
      timesCount1 = thisValueString.split('.')[1].length
    }
    if (valueString.indexOf('.') > 0) {
      timesCount2 = valueString.split('.')[1].length
    }
    const maxtimeCount = timesCount1 > timesCount2 ? timesCount1 : timesCount2
    this.value =
      (Math.pow(10, maxtimeCount) * this.value +
        Math.pow(10, maxtimeCount) * value) /
      Math.pow(10, maxtimeCount)
    return this
  } // 减法
  JSMath.prototype.sub = function(value) {
    const thisValueString = this.value.toString()
    const valueString = value.toString()
    let timesCount1 = 0
    let timesCount2 = 0
    if (thisValueString.indexOf('.') > 0) {
      timesCount1 = thisValueString.split('.')[1].length
    }
    if (valueString.indexOf('.') > 0) {
      timesCount2 = valueString.split('.')[1].length
    }
    const maxtimeCount = timesCount1 > timesCount2 ? timesCount1 : timesCount2
    this.value =
      (Math.pow(10, maxtimeCount) * this.value -
        Math.pow(10, maxtimeCount) * value) /
      Math.pow(10, maxtimeCount)
    return this
  }

  // 除法
  JSMath.prototype.div = function(value) {
    const thisValueString = this.value.toString()
    const valueString = value.toString()
    let timesCount1 = 0
    let timesCount2 = 0
    if (thisValueString.indexOf('.') > 0) {
      timesCount1 = thisValueString.split('.')[1].length
    }
    if (valueString.indexOf('.') > 0) {
      timesCount2 = valueString.split('.')[1].length
    }
    const maxtimeCount = timesCount1 > timesCount2 ? timesCount1 : timesCount2
    this.value =
      (Math.pow(10, maxtimeCount) * this.value) /
      (Math.pow(10, maxtimeCount) * value)
    return this
  }
  // 乘法
  JSMath.prototype.times = function(value) {
    const thisValueString = this.value.toString()
    const valueString = value.toString()
    let timesCount1 = 0
    let timesCount2 = 0
    if (thisValueString.indexOf('.') > 0) {
      timesCount1 = thisValueString.split('.')[1].length
    }
    if (valueString.indexOf('.') > 0) {
      timesCount2 = valueString.split('.')[1].length
    }
    const maxtimeCount = timesCount1 + timesCount2
    this.value =
      (Math.pow(10, maxtimeCount) *
        this.value *
        Math.pow(10, maxtimeCount) *
        value) /
      Math.pow(10, maxtimeCount * 2)
    return this
  }

  if (window.JSMath == undefined) {
    window.JSMath = function(value) {
      const result = new JSMath()
      result.from(value)
      return result
    }
  }
})()
